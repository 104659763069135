<template>
  <div class="about">
    <header class="page-header">
      <div class="container">
        <div class="page-header-flex-wrap no-margin-bottom">
          <h1 class="page-title section-header">{{$t("ask_question")}}</h1>
        </div>
      </div>
    </header>
    <section class="section medium">
      <div class="container">
        <div class="container-small">
          <!--<h2 class="text-center">{{$t("contact")}}</h2>-->
          <div class="contact-info-block">
            <div class="contact-info-flex-wrap">
              <div class="contact-info-content">
                <img src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f0dc6844ceca0bfc12d44e0_explore-white.svg" alt="" class="contact-info-icon">
                <div class="contact-info-text">Cyberrit Sp. z o.o.o.<br>ul.Grodzka 42/1<br>31-044 Krakow<br>Poland</div>
              </div>
              <div class="contact-info-content center">
                <!--
                <img src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f0efaf7a1c9062047ecbfd8_phone-white.svg" alt="" class="contact-info-icon">
                <div class="contact-info-text">

                  <a href="tel:800-000-0000" class="contact-utility-link">800-000-0000</a>
                </div>
                -->
              </div>
              <div class="contact-info-content">
                <img src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f0efaf7bfc9742306eff8c5_envelope-white.svg" alt="" class="contact-info-icon">
                <div class="contact-info-text">
                  <a href="mailto:support@elzabot.com" class="contact-utility-link">support@elzabot.com</a>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-form w-form" style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
            <form id="wf-form-Job-Application-Form" name="wf-form-Job-Application-Form" data-name="Job Application Form">
              <div class="text-field-flex-wrap">
                <div class="text-field-wrap small-margin-bottom">
                  <label for="Sign-In-First-Name" class="field-label">{{ $t("first_name") }}</label>
                  <input type="text" class="text-field w-input" maxlength="256" name="Sign-In-First-Name" data-name="Sign In First Name" :placeholder="$t('first_name')" id="Sign-In-First-Name" required="">
                </div>
                <div class="text-field-spacer"></div>
                <div class="text-field-wrap small-margin-bottom">
                  <label for="Sign-In-Last-Name" class="field-label">{{ $t("last_name") }}</label>
                  <input type="text" class="text-field w-input" maxlength="256" name="Sign-In-Last-Name" data-name="Sign In Last Name" :placeholder="$t('last_name')" id="Sign-In-Last-Name" required="">
                </div>
              </div>
              <div class="text-field-wrap">
                <label for="email" class="field-label">Email</label>
                <input type="email" class="text-field w-input" maxlength="256" name="email" data-name="Email" placeholder="Email" id="email" required="">
              </div>
              <div class="text-field-wrap">
                <label for="Subject" class="field-label">{{$t("subject")}}</label>
                <select id="Subject" name="Subject" data-name="Subject" required="" class="text-field select w-select">
                  <option value="">I have a question about...</option>
                  <option value="First">How this app works</option>
                  <option value="Second">My pricing plan</option>
                  <option value="Third">Other...</option>
                </select></div>
              <div class="text-field-wrap large-margin-bottom">
                <label for="email" class="field-label">{{$t("message")}}</label>
                <textarea :placeholder="$t('how_can_we_help_you_today')" maxlength="5000" id="field" name="field" data-name="Field" class="text-field min-height w-input"></textarea>
              </div>
              <input type="submit" :value="$t('submit')" data-wait="Please wait..." class="filled-btn full-width w-button">
            </form>
            <div class="success-message w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="error-message w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name:       'About'
}
</script>

<style lang=scss scoped>
.medium + .medium {
  padding-top: 0;
}

h2 {
  margin-bottom: 30px;
}

h5 {
  margin-top: 40px;
}
</style>
